import React from 'react';
import './hero.css';
import HubspotForm from 'react-hubspot-form'
import * as images from './../images';
import InfoCard from './../components/InfoCard';

const FormAhorro = () => {
  return (
    <div className="hero-contact-section">
      <div className="hero-contact-content">
        <img src={images.functionalBG} alt='img' />
        <h3>EXPERTOS en Seguros Corporativos</h3>
        <h1>¡Ahorra un 30% en los Seguros de Salud de tus colaboradores!</h1>
        <h2>Descubre cómo puedes obtener la cobertura que necesitas a precios más bajos que nunca.</h2>
        <div className='info-container'>
          <InfoCard size='0.9' imgFile={images.savingsIcon} boldText={'Ahorra hasta 30%'} text={'en tu seguro de salud.'} />
          <InfoCard size='1.5' imgFile={images.roiIcon} boldText={'ROI de hasta 62'} text={'por los beneficios exclusivos de Momentu deducibles de impuesto'} />
          <InfoCard imgFile={images.plansIcon} boldText={'Planes para todos'} text={'los presupuestos y necesidades.'} />
        </div>
      </div>
      <div className='hero-contact-form-container'>
        <div className={'hero-contact-form'}>
          <p>Obtén una cotización personalizada ahora mismo y <strong>empieza a ahorrar en tus gastos médicos.</strong></p>
          <HubspotForm
            portalId='20394660'
            formId={process.env.REACT_APP_FORM_ID}
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div></div>}
          />
        </div>
      </div>
    </div>
  );
}

export default FormAhorro;
